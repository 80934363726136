@import '../variables.scss';

.popout {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;

 .popout-inner {
    box-shadow: 0 3px 6px 0 #00000029;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border-radius: 5px;

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin: 0 1.5rem;
    }

   .text-box {
     flex-basis: 60%;
     max-width: 500px;
     
     @media (max-width: $breakpoint-mobile) {
      margin-bottom: 1rem;
      max-width: 330px;
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      margin: 0;
    }
  
    .text {
      font-size: 15px;
      margin: 0;
      margin-top: 0.25rem;
      font-weight: 500;
    }
   }


  .cta-box {
    display: flex;
    flex-basis: 40%;
    justify-content: space-between;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
 } 
}