@import '../variables.scss';

.split-section-image {
  .split-section-image-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      padding: 2rem 0;
    }

    .text-box {
      max-width: 450px;
      margin-left: 1.5rem;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: $breakpoint-mobile) {
        text-align: center;
        margin-left: 0;
        align-items: center;
      }

      .text.primary-color {
        @media (max-width: $breakpoint-mobile) {
          max-width: 250px;
        }
      }
  
      .subtitle {
        font-size: 26px;
        font-weight: 700;

        @media (max-width: $breakpoint-mobile) {
          font-size: 18px;
          margin: 0.5rem 0;
        }
      }
  
      .text {
        font-size: 15px;

        @media (max-width: $breakpoint-mobile) {
          max-width: 300px;
          margin: 0.5rem 0;
        }
      }

      .link {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: #2A6E94;
        font-weight: 700;

        @media (max-width: $breakpoint-mobile) {
          margin-bottom: 2rem;
        }

        .link-arrow {
          padding-left: 10px;
        }
      }
    }

    .image-box {
      margin-right: 1.5rem;

      .image {
        height: 400px;
        width: auto;

        @media (max-width: $breakpoint-small-laptop) {
          width: 350px;
          height: auto;
        }

        @media (max-width: $breakpoint-mobile) {
          height: 200px;
          width: auto;
          margin-top: 2rem;
        }
      }
    }
  }

  .split-section-image-inner.reverse {
    flex-direction: row-reverse;

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column-reverse;
    }
  }
}