@import '../variables.scss';

.trade-with-speed {
  background-color: #fff;
  
  .header {
    .header-inner {
      .content-box {
        @media (max-width: $breakpoint-tablet) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .text-box {
          @media (max-width: $breakpoint-tablet) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .title {
            font-size: 36px;
            font-weight: 500;
            margin-bottom: 2rem;

            @media (max-width: $breakpoint-mobile) {
              font-size: 24px;
              margin-top: 2rem;
            }
          }

          .subtitle {
            font-weight: 500;
            font-size: 16px;
            max-width: 400px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 16px;
            }
          }

          .cta-box {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            @media (max-width: $breakpoint-tablet) {
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }

            .cta-container {
              @media (max-width: $breakpoint-tablet) {
                margin: 0.5rem;
              }
              .cta {
                margin-right: 2rem;
                font-weight: 700;
                text-transform: capitalize;
  
                @media (max-width: $breakpoint-tablet) {
                  margin: 0;
                }
              }
            }
          }
        }

        .graphic {
          margin-right: -150px;
        
          .graphic-image { 
            @media (max-width: $breakpoint-laptop) {
              width: auto;
              height: 350px;
            }

            @media (max-width: $breakpoint-mobile) {
              display: initial;
              height: auto;
              width: 300px;
            }
          }
        }
      }
    }
  }

  .invest, 
  .webinars {
    background-color: #F8FAFF;    
  }

  .invest,
  .webinars,
  .trading-platforms {
    .title {
      font-size: 30px;
      font-weight: 700;
    }

  }

  .invest .split-section-image-inner,
  .webinars .split-section-image-inner {
    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
    }
  }
}