@import '../variables.scss';

.overlay {
  .overlay-inner {
    padding: 5rem 0;

    @media (max-width: $breakpoint-mobile) {
      padding: 2rem 0;
    }

    .green-box {
      background-color: $primary-color;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 3rem 2rem;
      width: 75%;

      @media (max-width: $breakpoint-mobile) {
        flex-direction: column-reverse;
        width: 100%;
        padding: 0;
      }

      .graphic-box {
        .graphic-image {
          height: 380px;
          margin-right: 1.5rem;
          
          @media (max-width: $breakpoint-mobile) {
            height: 200px;
            width: auto;
          }
        }
      }

      .text-box {
        background-color: #ffffffe6;
        border-radius: 5px;
        padding: 2rem 3rem;
        margin-right: -190px;
        box-shadow: -3px 3px 10px 4px #0000004d;

        @media (max-width: $breakpoint-mobile) {
          margin: auto;
          padding: 1rem;
          background-color: #ffffff;
        }

        .title {
          font-size: 26px;
        }

        .text {
          font-size: 15px;
        }
      }
    }
  }
}