@import '../variables.scss';

.scroll-down {
    margin-left: auto;
    margin-right: auto;
    
    .button {
        background-color: transparent;
        padding: 10px;
        border-radius: 50px;
        border: 2px solid $secondary-color;

        .arrow-icon {
            color: $secondary-color;
            height: 20px;
            width: 23px;
            padding: 2px;
            border-radius: 100px;
        }
    }
}