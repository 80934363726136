@import '../variables.scss';

.footer {
  margin-top: auto;

  .footer-inner {
    overflow: hidden;

    .logo-box {
      padding: 3rem 0;
      border-top: 1px solid #7878781a;
      border-bottom: 1px solid #7878781a;

      @media (max-width: $breakpoint-mobile) {
        display: flex;
        justify-content: center;
      }

      .logo {
        height: 130px;
        width: auto;

        @media (max-width: $breakpoint-mobile) {
          height: 80px;
        }
      }
    }

    .text-box {
      color: #333333;
      font-size: 11px;
      font-weight: 400;

      .text {
        font-family: 'Raleway', sans-serif !important;
      }

      strong {
        font-weight: 700;
      }

      a {
        text-decoration: none;
      }
    }
  }
}

.copyright {
  background-color: #50B848;
  text-align: center;

  .copyright-box {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @media (max-width: $breakpoint-tablet) {
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
    }

    .social-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        height: 15px;
        color: #fff;
        padding: 1rem;
      }
    }

    .notice {
      color: #fff;
      font-size: 12px;
      padding: 1rem 0;
      margin: 0;
    }

    .doc-links {
      display: flex;
      justify-content: space-between;

      .link {
        text-decoration: none;
        font-size: 12px;
        color: #fff;
        padding: 1rem;
      }
    }
  }
}