@import '../variables.scss';

.split-section {
  .split-section-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7rem 0;

    @media (max-width: $breakpoint-mobile) {
      flex-direction: column;
      padding: 2rem 0;
    }

    .text-box {
      max-width: 450px;

      @media (max-width: $breakpoint-mobile) {
        text-align: center;
      }

      .title {
        font-size: 26px;
        margin: 0;
        padding-bottom: 2rem;
      }

      .text {
        font-size: 14px;
        margin: 0;
      }

      .bullets {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }


        .bullet {
          font-size: 16px;
          font-weight: 500;
          padding: 0.75rem 0;
          background-image: url("../../assets/images/icons/tick.svg");
          background-repeat: no-repeat;
          padding-left: 30px;
          background-position-y: center;
          width: 35%;
        }
      }
    }

    .video-box {
      .video-embed {
        width: 510px;
        height: 310px;
        border-radius: 3px;
        box-shadow: -3px 3px 10px 4px #0000004d;

        @media (max-width: $breakpoint-mobile) {
          height: 200px;
          width: 350px;
          margin-top: 2rem;
        }
      }
    }
  }
}