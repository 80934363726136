@import '../variables.scss';

.header {
  background-repeat: no-repeat;
  background-position: top!important;
  flex-direction: column;
  display: flex;
  padding: 4rem 0;
  overflow: hidden;
  transition: filter ease 0.75s, -webkit-filter ease 0.75s;

  .header-inner {
    .logo-box {
      @media (max-width: $breakpoint-tablet) {
        text-align: center;
      }
    
      .logo {
        height: 45px;
        width: auto;
      }
    }

    .content-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .text-box {
        max-width: 600px;

        @media (max-width: $breakpoint-tablet) {
          text-align: center;
          margin: 1rem 0;
        }

        @media (max-width: $breakpoint-mobile) {
          max-width: 350px;
        }

        .title {
          color: #fff;
          font-weight: 600;
          margin: 0;
        }

        .subtitle {
          color: #fff;
          font-weight: 400;
        }

        .newsletter-form {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          @media (max-width: $breakpoint-tablet) {
            text-align: center;
            align-items: center;
          }

          .sub-text {
            font-family: 'Raleway', sans-serif !important;
            font-size: 22px;
            margin: 0;
            color: $secondary-color;
          }

          .email {
            font-size: 16px;
            border-radius: 3px;
            padding: 15px;
            margin: 1rem 0;
            width: 400px;
            border: none;

            @media (max-width: $breakpoint-mobile) {
              width: 300px;
            }
          }

          .checkbox-box {
            display: flex;
            padding-bottom: 1rem;

            .checkbox {
              color: #fff;
              font-size: 15px;
              padding-left: 5px;
            }
          }
        }
      }

      .graphic {
        .graphic-image {
          height: 550px;

          @media (max-width: $breakpoint-mobile) {
            display: none;
          }
        }
      }
    }
  }
}

.header.headerLoading {
  -webkit-filter: blur(20px);
  filter: blur(20px);
}