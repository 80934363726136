@import '../variables.scss';

.thank-you {
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #ffffff54;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.6s ease, visibility 0.6s ease;
  visibility: visible;

  .thank-you-inner {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 3pt 6pt #00000047;
    border-radius: 5pt;
    margin-top: 3rem;
    margin-bottom: 3rem;
    max-width: 650px;
    position: relative;

    .close-icon {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #54581f;
      border-radius: 40px;
      margin-top: -10px;
      margin-right: -10px;
      height: 25px;
      width: 25px;
      cursor: pointer;
    }

    .popup {
      text-align: center;

      .title {
        font-size: 28px;
        margin: 0;
        padding-bottom: 1rem;
      }
  
      .text {
        font-size: 15px;
        max-width: 70%;
        margin: 0 auto;
        padding-bottom: 1rem;
      }

      .cta-box {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.thank-you.hide-thank-you {
  opacity: 0;
  visibility: hidden;
}