@import '../variables.scss';

.steps-block {
  background-color: #000000;
  
  .steps-block-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5rem 0;

      .title {
        text-align: center;
        color: #fff;
        padding-bottom: 2rem;
        font-size: 26px;

        @media (max-width: $breakpoint-mobile) {
          font-size: 24px;
          padding-bottom: 1rem;
        }
      }

      .stepper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
        }

        .dotted-splitter {
          border: none;
          width: 100px;
          height: 1px;
          border-top: 2px dotted rgba(255, 255, 255, 0.5);
          margin-left: -90px;
          margin-right: -90px;
          width: 300px;

          @media (max-width: $breakpoint-mobile) {
            width: 200px;
          }
        }
      }
  }
}