@import '../variables.scss';

.trust-pilot {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  background-color: #F8FAFF;
  overflow: hidden;

  .trust-pilot-inner {
    background-color: #000032;
    padding: 2rem;
    border-radius: 3px;

    @media (max-width: $breakpoint-mobile) {
      margin: 0 1.5rem;
    }
  }
}