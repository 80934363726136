@import '../variables.scss';

.tabs {
  background-color: #F8FAFF;

  .tabs-inner {
    padding: 5rem 0;
    
    .title-box {
      text-align: center;
      max-width: 600px;
      margin: 0 auto;

      .title {
        font-size: 26px;
      }
      
      .subtitle {
        font-size: 15px;
      }
    }

    .tabs-box {
      .tabs-selector {
        .MuiTabs-flexContainer {
          display: flex;
          justify-content: center;

          @media (max-width: $breakpoint-mobile) {
            flex-direction: column;
            align-items: center;
          }
        }

        .PrivateTabIndicator-colorSecondary-3,
        .MuiTabs-indicator {
          background-color: $primary-color!important;
        }

        .tab {
          font-family: 'Raleway', sans-serif !important;
          color: $primary-color;
          font-size: 22px;
          font-weight: 600;
          text-transform: capitalize;
          margin: 0.5rem 1.5rem;
        }
      }

      .platform-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2rem 0;

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
        }

        .graphic-image {
          height: 300px;
          width: auto;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            height: auto;
          }
        }

        .text-box {
          width: 50%;
          max-width: 400px;
          font-size: 15px;

          @media (max-width: $breakpoint-mobile) {
            text-align: center;
            width: 80%;
            margin: 0 auto;
          }

          .bullets {
            list-style: none;
            padding: 0;
    
            .bullet {
              padding: 0.75rem 0;
              background-image: url("../../assets/images/icons/tick.svg");
              background-repeat: no-repeat;
              padding-left: 30px;
              background-position-y: center;
            }
          }
        }
      }
    }
  }
}