@mixin google-font($family) {
  @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap");
}

@include google-font("Raleway");

@mixin google-font($family) {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
}

@include google-font("Montserrat");

$breakpoint-small-mobile: 400px;
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-small-laptop: 992px;
$breakpoint-laptop: 1200px;
$container: 2rem 10rem;
$mobile-container: 1rem 1rem;
$laptop-container: 2rem 5rem;
$primary-color: #50B848;
$secondary-color: #D5E05B;

html {
  scroll-behavior: smooth;
  height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2 {
  font-family: 'Raleway', sans-serif !important;
}

h3, h4, h5, h6, p, a, li {
  font-family: 'Montserrat', sans-serif !important;
  line-height: 1.5;
}

.terms {
  text-decoration: underline;
  color: $primary-color;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.page-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primary-bg-color {
  background-color: $primary-color;

}

.secondary-bg-color {
  background-color: $secondary-color;
}

.primary-color {
  color: $primary-color;
}

.secondary-color {
  color: $secondary-color;
}

.white-text {
  color: #fff;
}

.line-breaker::before {
  content: ' '; 
  display: block;

  @media (max-width: $breakpoint-mobile) {
    content: initial;
    display: inline;
  }
}

.arrow-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .arrow-icon {
    padding-left: 20px;
    height: 20px;
    width: 20px;
    transition: padding 0.5s;
  }
}

.container {
  width: 1170px;
  margin: 0 auto;

  @media (max-width: $breakpoint-laptop) {
    width: 970px;
  }

  @media (max-width: $breakpoint-small-laptop) {
    width: 750px;
  }

  @media (max-width: $breakpoint-tablet) {
    width: 540px;
  }

  @media (max-width: $breakpoint-mobile) {
    width: 90%;
  }
}