@import '../variables.scss';

.swap-fees {
  background-color: #fff;
  
  .header {
      background-position-y: center!important;
      background-position-x: right!important;
      background-size: cover!important;

      @media (max-width: $breakpoint-tablet) {
        background-position-y: bottom!important;
        background-position-x: center!important;
        padding-bottom: 1rem;
      }

    .header-inner {
      .content-box {
        @media (max-width: $breakpoint-tablet) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .text-box {
          @media (max-width: $breakpoint-tablet) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .title {
            font-weight: 700;
            color: $primary-color;
            font-size: 36px;

            @media (max-width: $breakpoint-mobile) {
              font-size: 30px;
              margin-top: 2rem;
            }
          }

          .subtitle {
            font-size: 36px;
            font-weight: 700;
            max-width: 400px;
            margin: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 30px;
            }
          }

          .text {
            color: #fff;
            font-size: 26px;
            font-weight: 300;
            margin-bottom: 0;

            @media (max-width: $breakpoint-mobile) {
              font-size: 20px;
            }
          }

          .cta-box {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 2rem;
            @media (max-width: $breakpoint-tablet) {
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }

            .cta-container {
              @media (max-width: $breakpoint-tablet) {
                margin: 0.5rem;
              }

              .cta {
                margin-right: 2rem;
                font-weight: 700;
                text-transform: capitalize;
  
                @media (max-width: $breakpoint-tablet) {
                  margin: 0;
                }
              }
            }
          }
        }

        .graphic {      
          .graphic-image { 
            @media (max-width: $breakpoint-laptop) {
              width: auto;
              height: 350px;
            }

            @media (max-width: $breakpoint-mobile) {
              margin-top: 5rem;
              display: initial;
              height: auto;
              width: 550px;
            }
          }
        }
      }
    }

    .scroll-down {
      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
  }

  .zero-swap {
    .split-section-image-inner {      
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
      }
    }

    .top, 
    .text {
      font-size: 14px;
      margin: 0;
    }

    .text {
      max-width: 320px;
    }

    .title {
      font-size: 42px!important;
      margin: 0;
    }

    .terms {
      margin: 1rem 0;
    }

    .image {
      @media (max-width: $breakpoint-mobile) {
        height: 350px!important;
      }
    }
  }

  .existing {
    .split-section-image-inner  {
      @media (max-width: $breakpoint-mobile) {
        flex-direction: column;
      }
    }
    .image-box {
      margin-right: 3rem!important;

      @media (max-width: $breakpoint-mobile) {
        margin-right: 0!important;
      }
    }

    .text-box {
      margin-left: 3rem!important;

      @media (max-width: $breakpoint-mobile) {
        margin-left: 0!important;
      }
    }

    .image {
      border-radius: 3px;
    }

    .title {
      font-size: 26px;
      font-weight: 600;

      @media (max-width: $breakpoint-mobile) {
        font-size: 24px;
      }
    }

    .text {
      font-size: 14px;
    }
  }

  .credit-box {
    background-image: url('../../assets/images/backgrounds/credit-bg.png');
    background-size: cover;
    background-position-x: right;
    background-position-y: center;

    @media (max-width: $breakpoint-mobile) {
      background-image: url('../../assets/images/backgrounds/credit-m-bg.png');
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
    }

    .credit-box-inner {
      padding: 5rem 0;

      @media (max-width: $breakpoint-mobile) {
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: column;
      }

      .title {
        font-size: 26px;
        color: #fff;
      }

      .text {
        color: #fff;
        font-size: 14px;
        max-width: 450px;

        @media (max-width: $breakpoint-mobile) {
          margin-bottom: 25rem;
        }
      }
    }
  }
}