@import '../variables.scss';

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 300px;

  @media (max-width: $breakpoint-mobile) {
    height: auto;
    margin: 1rem 0;
  }

  .icon {
    height: 75px;
    width: 75px;
    margin-bottom: 2rem;
    margin-top: 28px;

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }

  .digit {
    background-color: $primary-color;
    color: #fff;
    border-radius: 20px;
    border: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }

  .subtitle {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-top: 2rem;

  }

  .text {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    max-width: 210px;
    margin: 0;
  }
}