.directory {
  .links-block {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .link {
      font-size: 18px;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #2A6E94;
      font-weight: 700;
      margin: 0.75rem 0;

      .link-arrow {
        padding-right: 10px;
      }
    }
  }
}