@import '../variables.scss';

.info-icons {
  background-color: #F8FAFF;

  .info-icons-inner {
    padding: 5rem 0;

    .title-box {
      text-align: center;
      max-width: 600px;
      margin: 0 auto;

      .title {
        font-size: 28px;
      }

      .subtitle {
        font-size: 15px;
      }
    }

    .icon-box {
      .icon-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 3rem 0;

        @media (max-width: $breakpoint-mobile) {
          flex-direction: column;
          margin: 0;
        }

        .icon {
          width: 45%;

          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            margin: 1rem 0;
          }

          .title-icon-box {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media (max-width: $breakpoint-mobile) {
              flex-direction: column;
              text-align: center;
            }

            .graphic-icon {
              height: 50px;
              width: 50px;
            }

            .title {
              font-size: 20px;
              color: $primary-color;
              padding-left: 1rem;

              @media (max-width: $breakpoint-mobile) {
                padding: 0;
              }
            }
          }

          .graphic-image {
            height: 250px;
            border-radius: 3px;
            box-shadow: -3px 3px 7px 2px #0000004d;

            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              height: auto;
            }
          }

          .text {
            font-size: 14px;

            @media (max-width: $breakpoint-mobile) {
              text-align: center;
            }
          }
        }
      }
    }
  }
}